import { isEmpty, round } from "lodash"

import { RootState } from "app/types"

export function selectOrderState(state: RootState) {
  return state.patientCheckout?.orders
}

export function selectSplitIt(state: RootState) {
  return state.patientCheckout?.splitit
}

export function selectIsSplitItInitializedOnce(state: RootState) {
  const { data, error } = selectSplitIt(state)
  return !Boolean(error) && Boolean(data)
}

export function selectIsSplitItInitialized(state: RootState) {
  const { data, error, isInitiating } = selectSplitIt(state)
  return !isInitiating && !Boolean(error) && Boolean(data)
}

export function selectSplitItPublicToken(state: RootState) {
  return selectSplitIt(state).data?.public_token
}

export function selectSplitItInstallmentPlanNumber(state: RootState) {
  return selectSplitIt(state).data?.installment_plan?.installment_plan_number
}

export function selectSplitItHowItWorksUrl(state: RootState) {
  return selectSplitIt(state).data?.learn_more_url
}

export function selectSplitItPrivacyPolicyUrl(state: RootState) {
  return selectSplitIt(state).data?.privacy_policy_url
}

export function selectSplitItTermsAndConditionsUrl(state: RootState) {
  return selectSplitIt(state).data?.terms_and_conditions_url
}

export function selectSplitItInstallmentAmounts(
  state: RootState
): number[] | undefined {
  const { data } = selectSplitIt(state)
  if (isEmpty(data?.installment_plan?.installments)) {
    const order = selectOrderState(state).order
    if (order) {
      return Array(3).fill(round(parseFloat(order.total_price) / 3, 2))
    }

    return undefined
  }
  return data?.installment_plan?.installments.map((i) => i.amount.value)
}
