import { useEffect } from "react"

import { CircularProgress, makeStyles } from "@material-ui/core"

import useAppSelector from "app/hooks/useAppSelector"
import useSplitItHostedFields from "app/main/patient-checkout/hooks/use-splitit-hosted-fields"
import {
  selectIsSplitItInitialized,
  selectSplitItPublicToken,
} from "app/main/patient-checkout/store/selectors"

const useStyles = makeStyles<any, { isReady: boolean }>((theme) => ({
  wrapper: {
    display: ({ isReady }) => (isReady ? "block" : "none"),
    marginBottom: theme.spacing(1),
  },
  loadingWrapper: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
}))

const SplitItCreditCardForm = () => {
  const isInitialized = useAppSelector(selectIsSplitItInitialized)
  const publicToken = useAppSelector(selectSplitItPublicToken)
  const { isReady, setup } = useSplitItHostedFields()
  const classes = useStyles({ isReady })

  useEffect(() => {
    if (isInitialized) {
      return setup({})
    }
  }, [isInitialized, publicToken])

  return (
    <>
      {!isReady && (
        <div className={classes.loadingWrapper}>
          <CircularProgress size={24} />
        </div>
      )}
      <div id="flex-form"></div>
    </>
  )
}

export default SplitItCreditCardForm
