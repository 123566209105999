import { useCallback } from "react"
import { useDispatch } from "react-redux"

import useSplitItHostedFields from "app/main/patient-checkout/hooks/use-splitit-hosted-fields"
import {
  PatientCheckoutFormData,
  PaymentMethodType,
  SplitItPaymentMethodDetails,
} from "app/types"
import retryOperation from "app/utils/retry-operation"

import { CheckoutPaymentMethodError } from "../errors"
import * as Actions from "../store/actions"

const INVALID_PAYMENT_PLAN_STATUS_RESPONSE = "Invalid Installment Plan Status"

export default function useCreateSplitItPaymentMethod(checkoutToken: string) {
  const dispatch = useDispatch()
  const { hostedFields } = useSplitItHostedFields()
  return useCallback(
    async (
      formData: PatientCheckoutFormData
    ): Promise<SplitItPaymentMethodDetails> => {
      if (!formData.payment_method?.splitit) {
        throw new CheckoutPaymentMethodError(
          "Unable to process payment for SplitIt",
          PaymentMethodType.SPLITIT
        )
      }

      /*
       * This uses an atypical retry mechanism to ensure that SplitIt has had time for validation to proceed.
       * SplitIt validates async from our form state, and that async validation does not trigger until the field is blurred.
       * This retry mechanism is meant to reduce the scenario where a user moves directly from the CVV to the submit button
       * in a very short period of time.
       */
      await retryOperation(async () => validateCreditCardFields(hostedFields), {
        delay: 200,
        retries: 3,
      })

      const billing_address = formData.payment_method.splitit
        .is_billing_same_as_shipping
        ? formData.default_shipping_address
        : formData.payment_method.splitit.billing_address
      const updateData = {
        billingAddress: {
          addressLine: billing_address.street_1,
          city: billing_address.city,
          state: billing_address.state,
          zip: billing_address.zipcode,
          country: "US",
        },
        consumerData: {
          fullName: `${formData.first_name} ${formData.last_name}`,
          email: formData.email,
          phoneNumber: formData.phone_number,
          cultureName: "en-US",
        },
      }

      try {
        hostedFields.updateDetails(updateData)
      } catch (error: any) {
        let errorMessage = error.response && error.response.data

        if (errorMessage === INVALID_PAYMENT_PLAN_STATUS_RESPONSE) {
          errorMessage =
            "Our session with SplitIt has expired. Please re-enter card details."

          // Generally, this occurs when the checkout screen was open for a long time and the plan has expired.
          // We re-initiate splitit to start with a fresh installment plan.
          dispatch(Actions.initiateSplitIt(checkoutToken))
        } else if (!errorMessage) {
          errorMessage =
            "Unable to process payment. Please try again, or use a different card"
        }

        throw new CheckoutPaymentMethodError(
          errorMessage,
          PaymentMethodType.SPLITIT
        )
      }

      return {
        billing_address,
      }
    },
    [checkoutToken, hostedFields]
  )
}

/**
 * Validates the credit card fields for SplitIt are filled appropriately.
 *
 * @param hostedFields the SplitIt HostedFields
 * @throws {CheckoutPaymentMethodError} if found to be invalid
 */
function validateCreditCardFields(hostedFields) {
  hostedFields.triggerValidation()
  if (hostedFields.isValid()) {
    return
  }

  throw new CheckoutPaymentMethodError(
    "Unable to process credit card information. Please try again, or use a different card.",
    PaymentMethodType.SPLITIT
  )
}
