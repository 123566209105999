import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { difference, isEmpty } from "lodash"
import { FieldPath, FormProvider } from "react-hook-form"

import { Container } from "@material-ui/core"
import * as Sentry from "@sentry/react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import Loading from "app/components/Loading"
import {
  COMPANY_KEY_INSURANCE_TOGGLE_PAIRS,
  LAB_COMPANY_KEY,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import SplitItHostedFieldsProvider from "app/main/patient-checkout/providers/SplitItFlexFieldsProvider"
import {
  PatientCheckoutTrackingEvents,
  trackPatientCheckoutEvent,
} from "app/services/segment"
import { stripePublishableAPIKey } from "app/settings"
import withReducer from "app/store/withReducer"
import {
  PatientCheckoutFormData,
  PatientCheckoutRequirement,
  PatientPortalOrder,
  PaymentMethodDetails,
} from "app/types"

import { forEachError } from "../../utils/for-each-error"
import CheckoutError from "./CheckoutError"
import CheckoutSuccess from "./CheckoutSuccess"
import { ConfirmationModal } from "./ConfirmationModal"
import NavBar from "./NavBar"
import PatientCheckoutForm from "./PatientCheckoutForm"
import PostCheckoutIdentityVerification from "./PostCheckoutIdentityVerification"
import { CheckoutValidationError } from "./errors"
import { FieldNames } from "./fields"
import { useCheckoutTokenFromLocation } from "./hooks"
import useCheckoutForm from "./hooks/use-checkout-form"
import useCreatePaymentMethod from "./hooks/use-create-payment-method"
import usePostSubmitFailure from "./hooks/use-post-submit-failure"
import useSubmitCheckout from "./hooks/use-submit-checkout"
import AchFieldsProvider from "./providers/AchFieldsProvider"
import * as Actions from "./store/actions"
import reducer from "./store/reducers"
import { selectOrderState } from "./store/selectors"
import { getKnownRequirements } from "./utils/checkout-utils"

const stripePromise = loadStripe(stripePublishableAPIKey)

interface PatientCheckoutWithOrderProps {
  checkoutToken: string
  order: PatientPortalOrder
  onSubmit: () => void
  orderRequestStatus: Actions.OrderRequestStatus
}

const PatientCheckoutWithOrder: React.FC<PatientCheckoutWithOrderProps> = ({
  checkoutToken,
  order,
  onSubmit,
  orderRequestStatus,
}) => {
  const [submitPending, setSubmitPending] = useState(false)
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodDetails>()
  const query = useQuery()
  const [beastCoastEnabledFlag] = useFeatureFlag(FeatureFlag.BeastCoast)
  const beastCoastEnabled =
    beastCoastEnabledFlag || query.get("beast_coast") === "1"

  const orderInsuredCompanies = useMemo(() => {
    const insuredLabCompanies = COMPANY_KEY_INSURANCE_TOGGLE_PAIRS.map(
      (pair) => pair.insuredCompanyKey
    )
    const insuredLabCompaniesOnOrder = order?.line_items?.reduce(
      (acc, lineItem) => {
        const labCompanyKey = lineItem.ordered_test?.lab_test?.lab_company?.key
        if (labCompanyKey && insuredLabCompanies.includes(labCompanyKey)) {
          const shortName =
            lineItem.ordered_test?.lab_test?.lab_company?.short_name
          if (shortName) acc.push(shortName)
        }
        return acc
      },
      [] as string[]
    )

    return insuredLabCompaniesOnOrder
  }, [order])

  // Identify user in FullStory
  useEffect(() => {
    const email = order?.patient.user.email
    const userId = order?.patient.user.uuid
    const displayName =
      order?.patient.user.first_name + " " + order?.patient.user.last_name

    if (email && window.FS && typeof window.FS.identify === "function") {
      window.FS.identify(userId, {
        displayName: displayName,
        email: email,
      })
    }
  }, [order?.patient.user.email, window.FS])

  const methods = useCheckoutForm(
    order,
    beastCoastEnabled,
    orderInsuredCompanies?.length > 0
  )
  const createPaymentMethod = useCreatePaymentMethod(checkoutToken)

  function setPaymentMethodError(message?: string) {
    if (message) {
      methods.setError(FieldNames.PAYMENT_METHOD_TYPE, { message })
    } else {
      methods.clearErrors(FieldNames.PAYMENT_METHOD_TYPE)
    }
  }

  /**
   * Submission handler for the form to create the payment method details in preparation for a confirmed submission.
   *
   * @param formData the submitted and validated form data
   */
  const submitForm = useCallback(
    async (formData: PatientCheckoutFormData) => {
      setSubmitPending(true)
      setPaymentMethodError(undefined)
      try {
        const paymentMethod = await createPaymentMethod(formData)
        if (paymentMethod) {
          setPaymentMethod(paymentMethod)
        }
        setIsConfirmationModelOpen(true)
        setSubmitPending(false)
      } catch (error: any) {
        setPaymentMethodError(error.message)
        setIsConfirmationModelOpen(false)
        setSubmitPending(false)
      }
    },
    [createPaymentMethod, setPaymentMethodError]
  )

  const submitCheckout = useSubmitCheckout(
    checkoutToken,
    order.id,
    orderInsuredCompanies?.length > 0
  )

  const postSubmitFailure = usePostSubmitFailure(checkoutToken)

  /**
   * Submission handler for the confirmation modal to create and submit a finalized checkout payload to create an order.
   */
  const submitConfirm = useCallback(async () => {
    setIsConfirmationModelOpen(false)
    setSubmitPending(true)

    const formData = methods.watch()

    try {
      await submitCheckout(formData, paymentMethod)
      trackPatientCheckoutEvent(
        PatientCheckoutTrackingEvents.PATIENT_CHECKOUT_SUBMIT,
        order,
        { used_insurance: orderInsuredCompanies?.length > 0 }
      )
      onSubmit()
    } catch (error) {
      if (error instanceof CheckoutValidationError) {
        forEachError(error.validationErrors, (name, message) => {
          if (name === "payment") {
            methods.setError(FieldNames.PAYMENT_METHOD_TYPE, {
              type: "server",
              message,
            })
            return
          }
          methods.setError(name as FieldPath<PatientCheckoutFormData>, {
            type: "server",
            message,
          })
        })
      }

      setSubmitPending(false)

      postSubmitFailure(error, formData)
    }
  }, [
    methods.setError,
    methods.watch,
    paymentMethod,
    postSubmitFailure,
    submitCheckout,
  ])

  // These are used to vary the copy in the form and the modal
  const [hasBRLTests, willTestsBeShipped] = useMemo(() => {
    let _hasBRLTests = false
    let _willTestsBeShipped = false

    for (const orderedTest of order.ordered_tests) {
      if (
        orderedTest.lab_test.lab_company.key === LAB_COMPANY_KEY.BIOREFERENCE
      ) {
        _hasBRLTests = true
      }
      if (
        !(
          orderedTest.lab_test.lab_company.only_in_house_phlebotomy ||
          orderedTest.instant_requisition ||
          orderedTest.sample_collection_coordinator !== "PATIENT"
        )
      ) {
        _willTestsBeShipped = true
      }
    }

    return [_hasBRLTests, _willTestsBeShipped]
  }, [order])

  return (
    <div className="pb-8 fs-unmask" style={{ marginBottom: -24 }}>
      <NavBar patient={order.patient} />
      <Container maxWidth="lg">
        <FormProvider {...methods}>
          <PatientCheckoutForm
            checkoutToken={checkoutToken}
            isSubmitPending={submitPending}
            onSubmit={submitForm}
            order={order}
            orderRequestStatus={orderRequestStatus}
            orderInsuredCompanies={orderInsuredCompanies}
            willTestsBeShipped={willTestsBeShipped}
            hasBRLTests={hasBRLTests}
          />
        </FormProvider>

        {isConfirmationModelOpen && (
          <ConfirmationModal
            formData={methods.watch()}
            onClose={() => setIsConfirmationModelOpen(false)}
            onConfirm={() => submitConfirm()}
            open={isConfirmationModelOpen}
            order={order}
            paymentMethod={paymentMethod}
            willTestsBeShipped={willTestsBeShipped}
          />
        )}
      </Container>
    </div>
  )
}

class UnknownCheckoutRequirementError extends Error {
  constructor(message) {
    super(message)
    this.name = "UnknownCheckoutRequirementError"
  }
}

/**
 * Reports any unknown checkout requirements provided by the server. This is a feedback
 * mechanism to ensure we don't propagate changes to the server that our client is unable
 * to handle.
 *
 * @param allRequirements the set of requirements listed on the PatientPortalOrder
 * @param knownRequirements the set of requirements we know how to handle from the PatientPortalOrder
 */
function useReportUnknownRequirements(
  allRequirements: Array<PatientCheckoutRequirement> = [],
  knownRequirements: Array<PatientCheckoutRequirement> = []
) {
  const unknownRequirements = useMemo(
    () => difference(allRequirements, knownRequirements),
    [allRequirements, knownRequirements]
  )
  const effectKey = JSON.stringify(unknownRequirements)
  useEffect(() => {
    if (!isEmpty(unknownRequirements)) {
      Sentry.captureException(
        new UnknownCheckoutRequirementError(
          `Unknown patient checkout requirement(s) provided to client: ${unknownRequirements}`
        )
      )
    }
  }, [effectKey])
}

const PatientCheckout = () => {
  const dispatch = useDispatch()
  const checkoutToken = useCheckoutTokenFromLocation()
  const { errorCode, order, status } = useAppSelector(selectOrderState)

  useEffect(() => {
    dispatch(Actions.getOrder(checkoutToken))
  }, [checkoutToken])

  const knownRequirements = useMemo(
    () => getKnownRequirements(order?.requirements),
    [order?.requirements]
  )

  useEffect(() => {
    if (order?.id) {
      trackPatientCheckoutEvent(
        PatientCheckoutTrackingEvents.CHECKOUT_PAGE_LOADED,
        order
      )
    }
  }, [order?.id])

  useReportUnknownRequirements(order?.requirements, knownRequirements)

  if (status === "loading") {
    return <Loading ariaLabel="Loading order" />
  } else if (status === "error") {
    return <CheckoutError errorCode={errorCode} />
  }

  if (!order || !checkoutToken) {
    return <div />
  }

  if (isEmpty(knownRequirements)) {
    return <CheckoutSuccess order={order} />
  }

  switch (knownRequirements[0]) {
    case "payment":
    case "patient_info": {
      return (
        <Elements stripe={stripePromise}>
          <SplitItHostedFieldsProvider
            checkoutToken={checkoutToken}
            order={order}
          >
            <AchFieldsProvider>
              <PatientCheckoutWithOrder
                checkoutToken={checkoutToken}
                onSubmit={() => dispatch(Actions.getOrder(checkoutToken))}
                order={order}
                orderRequestStatus={status}
              />
            </AchFieldsProvider>
          </SplitItHostedFieldsProvider>
        </Elements>
      )
    }
    case "identity_verification": {
      return (
        <PostCheckoutIdentityVerification
          checkoutToken={checkoutToken}
          onSubmit={() => dispatch(Actions.getOrder(checkoutToken))}
          order={order}
        />
      )
    }
  }
}

export default withReducer("patientCheckout", reducer)(PatientCheckout)
