import { createContext, useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { UseFormSetError } from "react-hook-form"

import { useModal } from "@ebay/nice-modal-react"

import { API } from "app/api"
import {
  PractitionerTypeOption,
  usePractitionerTypeOptions,
} from "app/hooks/usePractitionerTypes"
import { updatePractitioner } from "app/store/actions"
import { Practitioner } from "app/types"
import { handleApiError } from "app/utils"
import {
  PractitionerLicense,
  PractitionerLicenseCreateForm,
} from "types/practitioner-license"

import CredentialModalESignature from "./e-signature/CredentialModalESignature"
import { CredentialUploadLocation, LicenseType } from "./types"
import useCredentialTracking, {
  CredentialEvents,
  getCredentialUploadEventProperties,
} from "./use-credential-tracking"

export enum CredentialModalStep {
  LicenseType = "license_type",
  LicenseUpload = "license_upload",
  Signature = "signature",
}

interface ContextProps {
  modalOpen: boolean
  closeModal: () => void
  step: CredentialModalStep
  advanceStep: () => void
  goBackStep: () => void
  setDirty: (dirty: boolean) => void

  // Step 1 - LicenseType
  licenseType: LicenseType | undefined
  setLicenseType: (licenseType: LicenseType) => void

  // Step 2 - LicenseUpload
  practitionerTypeOptions: PractitionerTypeOption[]
  uploadLicense(
    formData: PractitionerLicenseCreateForm,
    setError: UseFormSetError<PractitionerLicenseCreateForm>
  ): Promise<void>

  // Step 3 - Signature
  isSigning: boolean
  setIsSigning(isSigning: boolean): void
  signatureComplete: boolean
  isSubmitting: boolean
}

const CredentialModalContext = createContext<ContextProps>(undefined as never)

export interface CredentialModalProviderProps {
  location: CredentialUploadLocation // only for event tracking
  practitioner: Practitioner
  children: React.ReactNode
  autoClose?: boolean
  onClose?: (fullVerificationComplete: boolean) => void
  onLicenseUpload?: (license?: PractitionerLicense) => void
}

function CredentialModalProvider({
  location,
  children,
  practitioner,
  autoClose = true,
  onClose,
  onLicenseUpload,
}: CredentialModalProviderProps) {
  const modal = useModal()
  const trackEvent = useCredentialTracking({ location, practitioner })

  const dispatch = useDispatch()
  const [step, setStep] = useState<CredentialModalStep>(
    practitioner.has_license
      ? CredentialModalStep.Signature
      : CredentialModalStep.LicenseType
  )
  const [licenseType, setLicenseType] = useState<LicenseType>()
  const [licenseComplete, setLicenseComplete] = useState(
    practitioner.has_license
  )
  const [signatureComplete, setSignatureComplete] = useState(
    practitioner.has_signature
  )
  const [isSigning, setIsSigning] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [dirty, setDirty] = useState(false)

  const { practitionerTypeOptions } = usePractitionerTypeOptions(
    practitioner.primary_practitioner_type
      ? [practitioner.primary_practitioner_type]
      : undefined
  )

  const closeModal = async () => {
    if (isSigning) return

    if (dirty) {
      if (!window.confirm("Are you sure? Unsaved changes will be lost.")) {
        return
      }
    } else if (!signatureComplete && step === CredentialModalStep.Signature) {
      if (
        !window.confirm(
          "Are you sure? You will not be able to place orders if you do not submit an E-Signature."
        )
      ) {
        return
      }
    }

    if (onClose) {
      if (signatureComplete && licenseComplete) {
        setIsSubmitting(true)
        await API.Practitioner.getVerificationStatus()
          .then((response) => {
            const hasSignature = response.data.has_completed_esignature
            const hasValidLicense = response.data.has_valid_license
            onClose(hasSignature && hasValidLicense)
          })
          .catch((error) => {
            dispatch(handleApiError(error))
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      } else {
        onClose(false)
      }
    }
    // Remove the modal rather than hiding it b/c we want to reset the state
    modal.remove()
  }

  useEffect(() => {
    if (!autoClose) return
    if (!dirty && !isSigning && signatureComplete && licenseComplete) {
      closeModal()
    }
  }, [autoClose, dirty, isSigning, signatureComplete, licenseComplete])

  const advanceStep = () =>
    setStep((prev) => {
      if (prev === CredentialModalStep.LicenseType)
        return CredentialModalStep.LicenseUpload
      if (prev === CredentialModalStep.LicenseUpload)
        return CredentialModalStep.Signature
      return prev
    })

  const goBackStep = () =>
    setStep((prev) => {
      if (prev === CredentialModalStep.Signature)
        return CredentialModalStep.LicenseUpload
      if (prev === CredentialModalStep.LicenseUpload)
        return CredentialModalStep.LicenseType
      return prev
    })

  const uploadLicense = async (
    formData: PractitionerLicenseCreateForm,
    setError: UseFormSetError<PractitionerLicenseCreateForm>
  ) => {
    const { npi_number, practitioner_type_id, ...licenseFormData } = formData
    const selectedPractitionerType =
      practitionerTypeOptions.find((o) => o.value === practitioner_type_id) ??
      practitioner.primary_practitioner_type

    try {
      const response = await API.PractitionerLicenses.post({
        ...licenseFormData,
        expiration_date: formData.expiration_date
          ? new Date(
              // the fix to bug of having date with - or + 1 day in DB beacuse of the timezone offset
              // Convert date to UTC to fix timezone offset issues
              // 60000 = 60 seconds * 1000 milliseconds (converts minutes to milliseconds)
              formData.expiration_date.getTime() -
                formData.expiration_date.getTimezoneOffset() * 60000
            )
              .toISOString()
              // Take first 10 characters to get just the date portion (YYYY-MM-DD) from ISO string
              .slice(0, 10)
          : undefined,
      })

      setLicenseComplete(true)
      trackEvent(
        CredentialEvents.CREDENTIAL_UPLOADED,
        getCredentialUploadEventProperties(
          formData,
          practitioner,
          selectedPractitionerType
        )
      )
      onLicenseUpload?.(response.data)

      await dispatch(
        updatePractitioner({
          npi_number,
          primary_practitioner_type: selectedPractitionerType,
        })
      )
      setDirty(false)
      advanceStep()
    } catch (error: any) {
      if (error.response?.data?.errors) {
        const errorStr = error.response.data.errors[0]
        // Convert Python string format to valid JSON
        const jsonStr = errorStr
          .replace(/'/g, '"')
          .replace(/ErrorDetail\(string=|, code='invalid'\)/g, "")

        try {
          const errorObj = JSON.parse(jsonStr)
          Object.entries(errorObj).forEach(([field, messages]) => {
            setError(field as keyof PractitionerLicenseCreateForm, {
              type: "manual",
              message: Array.isArray(messages) ? messages[0] : messages,
            })
          })
        } catch (e) {
          dispatch(handleApiError(error))
        }
      } else {
        dispatch(handleApiError(error))
      }
    }
  }

  return (
    <CredentialModalContext.Provider
      value={{
        modalOpen: modal.visible,
        closeModal,
        step,
        advanceStep,
        goBackStep,
        setDirty,
        licenseType,
        setLicenseType,
        practitionerTypeOptions,
        uploadLicense,
        isSigning,
        setIsSigning,
        signatureComplete,
        isSubmitting,
      }}
    >
      <CredentialModalESignature
        show={isSigning}
        onComplete={() => {
          setIsSigning(false)
          setSignatureComplete(true)
          trackEvent(CredentialEvents.SIGNATURE_UPLOADED)
        }}
        onClose={() => setIsSigning(false)}
      />
      {children}
    </CredentialModalContext.Provider>
  )
}

export function useCredentialModalContext() {
  return useContext(CredentialModalContext)
}

export default CredentialModalProvider
