import { createContext } from "react"

import { ScriptStatus } from "app/hooks/use-script"

export interface HostedFieldsSetup {}

export interface HostedFieldsContextShape {
  /**
   * The HostedFields instance if it has been setup.
   */
  hostedFields: any | null
  /**
   * Whether or not the hosted fields are ready.
   */
  isReady: boolean
  /**
   * Sets up the hosted fields instance and attaches to the credit card form.
   */
  setup: (config: HostedFieldsSetup) => void
  /**
   * The status of the SplitIt SDK script.
   */
  status: ScriptStatus
}

export default createContext<HostedFieldsContextShape>({
  hostedFields: null,
  isReady: false,
  setup: () => {},
  status: "loading",
})
