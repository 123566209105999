import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import * as Sentry from "@sentry/react"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/main/patient-checkout/store/actions"
import {
  selectSplitItInstallmentPlanNumber,
  selectSplitItPublicToken,
} from "app/main/patient-checkout/store/selectors"
import { SPLITIT_NUM_INSTALLMENTS } from "app/settings"
import { PatientPortalOrder } from "app/types"

import SplitItHostedFieldsContext from "../contexts/SplitItHostedFieldsContext"
import useSplitItSDK from "../hooks/use-splitit-sdk"
import { isPaymentRequired } from "../utils/checkout-utils"

interface SplitItFlexFieldsProviderProps {
  checkoutToken: string
  children: JSX.Element
  order: PatientPortalOrder
}

/**
 * Provider used for setting up the global SplitIt FlexFields instance for consumption within the checkout flow.
 *
 * This provider does the following on mount:
 * 1. Loads the SplitIt FlexFields SDK (only if payment is required).
 * 2a. If successful, initiates a pending payment plan with SplitIt to retrieve a public token.
 * 2b. If failure, dispatch a failure action for loading SplitIt.
 * 3. Provides a helper method to initialize the FlexFields credit card form.
 */
const SplitItHostedFieldsProvider = ({
  checkoutToken,
  children,
  order,
}: SplitItFlexFieldsProviderProps) => {
  const dispatch = useDispatch()
  const [featureDisabled] = useFeatureFlag(FeatureFlag.SplitItDisabled)
  const shouldInitialize = !featureDisabled && isPaymentRequired(order)
  const status = useSplitItSDK(shouldInitialize)
  const publicToken = useAppSelector(selectSplitItPublicToken)
  const installmentPlanNumber = useAppSelector(
    selectSplitItInstallmentPlanNumber
  )
  const [isReady, setIsReady] = useState(false)
  const [hostedFields, setHostedFields] = useState<any | null>(null)

  useEffect(() => {
    if (!shouldInitialize) {
      return
    }

    if (status === "ready") {
      dispatch(Actions.initiateSplitIt(checkoutToken))
    } else if (status === "error") {
      const error = new Error("Failed to load SplitIt Flex Fields SDK")
      Sentry.captureException(error)
      dispatch(Actions.failureInitiateSplitIt(error))
    }
  }, [checkoutToken, shouldInitialize, status])

  const setup = useCallback(
    (config) => {
      setIsReady(false)
      const hostedFields = Splitit.FlexForm.setup({
        culture: "en-US",
        container: "flex-form",
        ipn: installmentPlanNumber,
        numberOfInstallments: SPLITIT_NUM_INSTALLMENTS,
        paymentButton: {
          isCustom: true,
        },
        ...config,
      }).ready(() => {
        hostedFields.show()

        setIsReady(true)
      })
      setHostedFields(hostedFields)
      return () => hostedFields.hide()
    },
    [publicToken]
  )

  const context = useMemo(
    () => ({
      hostedFields,
      isReady,
      setup,
      status,
    }),
    [hostedFields, isReady, setup, status]
  )

  return (
    <SplitItHostedFieldsContext.Provider value={context}>
      {children}
    </SplitItHostedFieldsContext.Provider>
  )
}

export default SplitItHostedFieldsProvider
