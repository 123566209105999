import { useState } from "react"

import { FieldError } from "react-hook-form"

import { FormHelperText, Grid } from "@material-ui/core"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  PaymentMethod,
  StripeCardNumberElement,
  StripeError,
} from "@stripe/stripe-js"

import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { colors, veryLightGray } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  stripeCardForm: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(2.0),
  },
  stripeInput: {
    padding: theme.spacing(1.0),
    backgroundColor: veryLightGray,
    borderRadius: 6,
    border: `1px solid ${colors.blueGray[300]}`,
  },
  stripeCardFooter: {
    display: "flex",
    flexFlow: "column nowrap",
    [theme.breakpoints.up("sm")]: {
      flexFlow: "row nowrap",
      justifyContent: "space-between",
    },
  },
  continueButton: {
    marginLeft: "auto",
  },
}))

export interface PaymentCollectionStripeCardFormProps {
  amountFormatted: string
  error?: FieldError
  onError: (error: StripeError) => void
  onSubmit: (paymentMethod: PaymentMethod) => void
}

export default function StripeCardForm({
  amountFormatted,
  error,
  onError,
  onSubmit,
}: PaymentCollectionStripeCardFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const [isSubmitPending, setIsSubmitPending] = useState(false)

  async function handleContinue() {
    if (!stripe || !elements) {
      // should never be reached as we disable submission until loaded
      return
    }

    setIsSubmitPending(true)

    // Get a reference to a mounted CardNumberElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(
      CardNumberElement
    ) as StripeCardNumberElement
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    })

    setIsSubmitPending(false)

    if (error) {
      onError(error)
    } else if (paymentMethod) {
      onSubmit(paymentMethod)
    }
  }

  return (
    <div className={classes.stripeCardForm}>
      <BodyText size="sm">
        {`Provide information of the card to be charged ${amountFormatted}. Credit and Debit accepted.`}
      </BodyText>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6} data-testid="stripe-card-number">
          <CardNumberElement
            className={classes.stripeInput}
            options={{ placeholder: "Card Number" }}
          />
        </Grid>

        <Grid item xs={6} md={3} data-testid="stripe-card-exp">
          <CardExpiryElement
            className={classes.stripeInput}
            options={{ placeholder: "MM / YY" }}
          />
        </Grid>

        <Grid item xs={6} md={3} data-testid="stripe-card-cvc">
          <CardCvcElement
            className={classes.stripeInput}
            options={{ placeholder: "CVC" }}
          />
        </Grid>
      </Grid>

      <div className={classes.stripeCardFooter}>
        {error && <FormHelperText error>{error.message}</FormHelperText>}

        <Button
          className={classes.continueButton}
          color="primary"
          disabled={!stripe || !elements}
          loading={isSubmitPending}
          onClick={handleContinue}
          size="small"
          variant="contained"
          type="button"
        >
          {"Continue"}
        </Button>
      </div>
    </div>
  )
}
