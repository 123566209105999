import { makeStyles, Theme } from "@material-ui/core"

import ShieldIcon from "app/assets/icons/shield.svg"
import RupaSmallLogo from "app/assets/images/storefront/rupa-small-logo.svg"
import { colors } from "app/theme"

const styles = (theme: Theme) => ({
  footerContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    background: "white",
    justifyContent: "center",
    alignItems: "center",
    "overflow-x": "hidden",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "45px 0",
    width: "90%",
    maxWidth: 1150,
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column-reverse",
      gap: 21,
    },
  },
  text: {
    color: colors.blueGray[500],
    "& a": {
      fontWeight: 600,
      color: colors.blueGray[500],
      "text-decoration": "underline !important",
    },
  },
})

const useStyles = makeStyles(styles)

const Footer = () => {
  const classes = useStyles()
  return (
    <div className={classes.footerContainer}>
      <div className={classes.content}>
        <div>
          <img
            src={RupaSmallLogo}
            alt="rupa logo"
            style={{ position: "relative", top: 3, marginRight: 6 }}
          />
          <span className={classes.text}>
            LabShop Powered by{" "}
            <a
              href="https://www.rupahealth.com/labshop"
              target="_blank"
              rel="noreferrer"
            >
              Rupa Health
            </a>
            .
          </span>
        </div>
        <div>
          <img
            src={ShieldIcon}
            alt="shield icon"
            style={{ position: "relative", top: 3, marginRight: 6 }}
          />
          <span className={classes.text}>Credit and Debit Cards Accepted.</span>
        </div>
      </div>
    </div>
  )
}

export default Footer
