import { makeStyles, Theme } from "@material-ui/core"

import FAQs from "app/main/patient-portal/FAQs"

import StorefrontSupport from "./StorefrontSupport"

const styles = (theme: Theme) => ({
  faqContentContainer: {
    width: "100%",
    background: "white",
    display: "flex",
    justifyContent: "center",
    "flex-direction": "column",
    alignItems: "center",
    paddingTop: 70,
    [theme.breakpoints.down("sm")]: {
      padding: "75px 0px",
    },
  },
  faqContainer: {
    width: "100%",
    maxWidth: "900px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  supportContainer: {
    marginTop: 58,
    marginBottom: 25,
    width: "100%",
  },
})

const useStyles = makeStyles(styles)

const StorefrontFAQ = () => {
  const classes = useStyles()

  const faqs = [
    {
      question: "What can I expect in the Rupa Health process? 😊",
      answer:
        "[Rupa Health](https://www.rupahealth.com/patients) **is a lab ordering platform with one simple goal: make the lab testing process easier.** We partner with over 30+ labs to provide thousands of tests your practitioner can offer to their community so you can discover the root cause of your symptoms. Once you order testing here via the practitioner’s link, our team will guide you through the testing process until you receive results. We do not provide medical services, but we are here to help you in your health journey!<br /><br />You can reach our support team directly at [support@rupahealth.com](mailto:support@rupahealth.com) or view additional information here in your [Patient Resource Center](https://support.rupahealth.com/article/1147-rupaptprocess).",
    },
    {
      question: "Payment & Insurance 💳",
      answer:
        "You can pay for your order using all major credit and debit cards. At this time, Insurance reimbursement is not available for these tests. If you have any questions about completing your payment, you can reach our team at [support@rupahealth.com](mailto:support@rupahealth.com).",
    },
    {
      question: "How do I complete my lab test? 🧪",
      answer:
        "Rupa will walk you through the entire process! Two days after your kits ship, you’ll receive a personalized instructions email from us. This email includes an easy to follow cheat sheet on how to collect your lab test samples, fill out the requisition form and mail your kit back to the lab. It also provides local blood draw options if your test requires phlebotomy.<br /><br />Check out our [resource page here](https://support.rupahealth.com/article/1180-how-do-i-complete-my-test) for everything you need to know about how to complete your lab test! You can also always reach out to our team at [support@rupahealth.com](mailto:support@rupahealth.com) with any questions.",
    },
    {
      question: "How do I fill out the test requisition form? 📄",
      answer:
        "Your personalized instructions e-mail will show you exactly how to fill out your requisition form before you send your sample back to the lab. Check out our [Requisition Form FAQ](https://support.rupahealth.com/article/1475-requisition-forms-faq) for more information.",
    },
    {
      question: "How do I complete a test requiring a blood draw? 🩸",
      answer:
        "If you order a test that requires a blood draw from a phlebotomist, our team will automatically send you local blood draw options in your personalized email instructions. These options often include walk-in labs and mobile phlebotomists. All the information you need to schedule your blood draw appointment will be provided, including the additional cost! If you have any questions about blood draws you can check out our [FAQ here](https://support.rupahealth.com/article/1401-bloodtestoverview) or contact us at [support@rupahealth.com](mailto:support@rupahealth.com).",
    },
    {
      question: "Shipping & Tracking 🚛",
      answer:
        "Once your tests have been paid for and you've provided your shipping information, kits will be drop-shipped directly from the labs. You will receive a confirmation email from Rupa letting you know the estimated arrival day of your kit. If you have any questions about shipping & tracking, you can read the [FAQ here](https://support.rupahealth.com/article/1472-shippingtracking) or contact us at [support@rupahealth.com](mailto:support@rupahealth.com).",
    },
    {
      question: "What is the turnaround time for results? ⏳",
      answer:
        "Turnaround times vary by lab. They are calculated from the date that the sample is received at the lab. You can see [a full list of turnaround times by each individual lab company here](https://www.rupahealth.com/lab-status). If you have any questions about your sample, you can reach us at [support@rupahealth.com](mailto:support@rupahealth.com).",
    },
    {
      question: "Where can I view my results? 📈",
      answer:
        "Results will be automatically released to you via email once they become available! If you have any questions about receiving results, you can always message our team at [support@rupahealth.com](mailto:support@rupahealth.com).",
    },
  ]

  return (
    <div className={classes.faqContentContainer}>
      <div className={classes.faqContainer}>
        <FAQs faqs={faqs} />
      </div>
      <div className={classes.supportContainer}>
        <StorefrontSupport />
      </div>
    </div>
  )
}

export default StorefrontFAQ
