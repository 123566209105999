import axios from "axios"

import { getApiBaseUrl } from "app/utils"
import { Patient } from "types/patient"
import { PractitionerLicense } from "types/practitioner-license"

import {
  HealthCategory,
  LabTestBundle,
  LabTestShareBundle,
  SupportedCountry,
} from "./types"

export const API = {
  Auth: {
    updatePassword: (password) =>
      axios.put(getApiBaseUrl() + `/api/password_update/`, {
        password,
      }),
    patientSignup: (data) =>
      axios.post(getApiBaseUrl() + `/api/patient_portal/signup`, data),
  },
  BankAccount: {
    delete: (id) => axios.delete(getApiBaseUrl() + `/api/bankaccount/${id}`),
    getList: () => axios.get(getApiBaseUrl() + "/api/bankaccount/"),
    post: (publicToken, accountId) =>
      axios.post(getApiBaseUrl() + "/api/bankaccount/", {
        public_token: publicToken,
        account_id: accountId,
      }),
    patch: (id, payload) =>
      axios.patch(getApiBaseUrl() + `/api/bankaccount/${id}`, payload),
  },
  ShareBundle: {
    // Create a share bundle
    create: (data) =>
      axios.post(getApiBaseUrl() + `/api/labtestsharebundles/`, data),
    // Get the share bundle by ID
    get: (id) =>
      axios.get<LabTestShareBundle>(
        getApiBaseUrl() + `/api/labtestsharebundles/${id}/load/`
      ),
    // Copy the shared bundle to the current practitioner's account
    copy: (id) =>
      axios.post<LabTestBundle>(
        getApiBaseUrl() + `/api/labtestsharebundles/${id}/copy/`
      ),
    // Get any share bundles which exist for the given bundle
    // If the lab tests have been changed since the bundle was shared, this will
    // return an empty response
    getSharedBundle: (id) =>
      axios.get(getApiBaseUrl() + `/api/labtestsharebundles/${id}/get_shared/`),
  },
  Clinic: {
    getPractitioners: (id) =>
      axios.get(getApiBaseUrl() + `/api/clinic/${id}/practitioners/`),
    deleteLogo: (id) =>
      axios.delete(getApiBaseUrl() + `/api/normalized/clinics/${id}/logo/`),
    get: (id) => axios.get(getApiBaseUrl() + `/api/normalized/clinics/${id}/`),
  },
  InOfficeKitOrderedProducts: {
    create: (id, data) =>
      axios.post(
        getApiBaseUrl() +
          `/api/normalized/in_office_kit_order/${id}/bulk_ordered_products/`,
        data
      ),
  },
  Coupon: {
    getList: (params) =>
      axios.get(getApiBaseUrl() + "/api/coupon/", {
        params,
      }),
    createCouponUser: (group_code) =>
      axios.post(getApiBaseUrl() + `/api/couponuser/`, { group_code }),
    deleteCouponUser: (coupon_id) =>
      axios.delete(getApiBaseUrl() + `/api/couponuser/?coupon_id=${coupon_id}`),
  },
  StripeConnectAccount: {
    post: () => axios.post(getApiBaseUrl() + "/api/stripeconnectaccounts/"),
    getList: () => axios.get(getApiBaseUrl() + `/api/stripeconnectaccounts/`),
    // Unused
    get: (id) =>
      axios.get(getApiBaseUrl() + `/api/stripeconnectaccounts/${id}/`),
    dashboardLink: (stripeConnectAccountId: string, useLabshopUrl = false) =>
      axios.post(
        getApiBaseUrl() +
          "/api/stripeconnectaccounts/" +
          stripeConnectAccountId +
          "/dashboard_link/",
        {
          use_labshop_url: useLabshopUrl,
        }
      ),
  },
  CustomFee: {
    get: (stripeConnectAccountId) =>
      axios.get(
        getApiBaseUrl() +
          `/api/stripeconnectaccounts/${stripeConnectAccountId}/customfees/`
      ),
    post: (
      stripeConnectAccountId: string,
      name: string,
      price: number,
      type: string
    ) =>
      axios.post(
        getApiBaseUrl() +
          "/api/stripeconnectaccounts/" +
          stripeConnectAccountId +
          "/customfees/",
        {
          name,
          price,
          type,
        }
      ),
    put: (customFeeId, name, price) =>
      axios.put(
        getApiBaseUrl() +
          "/api/stripeconnectaccounts/customfees/" +
          customFeeId +
          "/",
        {
          name,
          price,
        }
      ),
  },
  CustomFeeLineItem: {
    create: (order_id, custom_fee_id, data = {}) => {
      return axios.post(
        getApiBaseUrl() + `/api/order/${order_id}/customfeelineitems/`,
        {
          custom_fee_id: custom_fee_id,
          ...data,
        }
      )
    },
    put: (orderId, customFeeLineItemId, customFeeData) =>
      axios.put(
        getApiBaseUrl() +
          `/api/order/${orderId}/customfeelineitems/${customFeeLineItemId}/`,
        customFeeData
      ),
    delete: (orderId, customFeeLineItemId) =>
      axios.delete(
        getApiBaseUrl() +
          `/api/order/${orderId}/customfeelineitems/${customFeeLineItemId}/`
      ),
    refund: (orderId, customFeeLineItemId) =>
      axios.post(
        getApiBaseUrl() +
          `/api/order/${orderId}/customfeelineitems/${customFeeLineItemId}/refund/`
      ),
  },
  DashboardBanner: {
    // legacy, only used behind feature flag
    get: () => axios.get(getApiBaseUrl() + `/api/dashboardbanner/`),
  },
  Esignature: {
    initialize: () => axios.post(getApiBaseUrl() + `/api/esignatures/`, {}),
  },
  ActiveAlerts: {
    // legacy, only used behind feature flag
    get: () => axios.get(getApiBaseUrl() + `/api/activealerts/`),
  },
  Onboarding: {
    markTaskComplete: (task) =>
      axios.post(getApiBaseUrl() + `/api/onboarding/mark_complete/`, {
        key: task,
      }),
  },
  HealthCategories: {
    getList: (orderBy?: keyof HealthCategory) =>
      axios.get<HealthCategory>(getApiBaseUrl() + "/api/healthcategories/", {
        params: { ...(orderBy ? { order_by: orderBy } : {}) },
      }),
  },
  ICD10Code: {
    getList: (search, cancelToken) =>
      axios.get(getApiBaseUrl() + `/api/icd10code/?search=${search}`, {
        cancelToken: cancelToken,
      }),
  },
  Invoices: {
    download: (id) =>
      axios.get(getApiBaseUrl() + `/api/invoices/${id}/download`),
  },
  Receipts: {
    download: (orderPaymentId) =>
      axios.get(getApiBaseUrl() + `/api/receipts/${orderPaymentId}/download`),
  },
  LabCompany: {
    // Unused
    get: (id) => axios.get(getApiBaseUrl() + `/api/labcompany/${id}/`),
    getList: ({ include_importable = false } = {}) =>
      axios.get(
        getApiBaseUrl() +
          "/api/labcompany/" +
          (include_importable ? "?include_importable=true" : "")
      ),
    registerLabCompany: (id, labCompanyId) =>
      axios.post(
        getApiBaseUrl() + `/api/practitioner/${id}/register-lab-company/`,
        {
          lab_company_id: labCompanyId,
        }
      ),
  },
  LabTest: {
    getList: (queryString: string, abortController?: AbortController) =>
      axios.get(getApiBaseUrl() + "/api/labtest/?" + queryString, {
        signal: abortController?.signal,
      }),
    get: (id, queryString) =>
      axios.get(getApiBaseUrl() + `/api/labtest/${id}/` + queryString),
  },
  LabTestType: {
    getList: () => axios.get(getApiBaseUrl() + "/api/labtesttype/"),
    // Unused
    get: (id) => axios.get(getApiBaseUrl() + `/api/labtesttype/${id}/`),
  },
  OrderedTest: {
    put: (id, ordered_test) =>
      axios.put(getApiBaseUrl() + `/api/orderedtest/${id}/`, ordered_test),
    requestConsult: (id) =>
      axios.post(getApiBaseUrl() + `/api/orderedtest/${id}/requestconsult/`),
    sendPatientResults: (id) =>
      axios.post(
        getApiBaseUrl() + `/api/orderedtest/${id}/sendpatientresults/`
      ),
  },
  OrderedComboGroupTest: {
    post: (payload) =>
      axios.post(getApiBaseUrl() + "/api/orderedtestcombogroup/", payload),
    patch: (id, payload) =>
      axios.patch(
        getApiBaseUrl() + `/api/orderedtestcombogroup/${id}/`,
        payload
      ),
  },
  Order: {
    get: (id) => axios.get(getApiBaseUrl() + `/api/order/${id}/`),
    cancel: (id, cancellation_reason) =>
      axios.post(getApiBaseUrl() + `/api/order/${id}/cancel/`, {
        cancellation_reason: cancellation_reason,
      }),
    getCheckoutStatus: (id) =>
      axios.get(getApiBaseUrl() + `/api/order/${id}/checkout/`),
    getInstantRequisitions: (id) =>
      axios.get(getApiBaseUrl() + `/api/order/${id}/instantrequisitions/`),
    getLabCompanyRequisition: (orderId, labCompanyId, orderedTestId) =>
      axios.get(
        getApiBaseUrl() +
          `/api/order/${orderId}/labcompanyrequisition/${labCompanyId}/`,
        {
          params: {
            ordered_test_id: orderedTestId,
          },
        }
      ),
    getPatientCheckoutUrl: (id) =>
      axios.get(getApiBaseUrl() + `/api/order/${id}/patientcheckout`),
    getDefaultOrderStatuses: (params) =>
      axios.get(getApiBaseUrl() + `/api/dashboard-order-statuses/`, {
        params: params,
      }),
    orderAgain: (data) =>
      axios.post(getApiBaseUrl() + `/api/orderagain/`, data),
    addBundle: (id, data) =>
      axios.post(getApiBaseUrl() + `/api/order/${id}/bundle/addbundle/`, data),
    removeBundle: (id, data) =>
      axios.post(
        getApiBaseUrl() + `/api/order/${id}/bundle/removebundle/`,
        data
      ),
    sendAccountActivationLinks: (id) =>
      axios.post(
        getApiBaseUrl() + `/api/order/${id}/sendaccountactivationlinks/`
      ),
    toggleInsurance: (id, toggleTo, companyKey) =>
      axios.post(getApiBaseUrl() + `/api/order/${id}/toggle-insurance/`, {
        toggle_to: toggleTo,
        company_key_to_toggle: companyKey,
      }),
  },
  OrderPanel: {
    update: (orderId, data) =>
      axios.patch(getApiBaseUrl() + `/api/order/${orderId}/panel/`, data),
    delete: (orderId, orderedTestIds) =>
      axios.delete(getApiBaseUrl() + `/api/order/${orderId}/panel/`, {
        data: { ordered_test_ids: orderedTestIds },
      }),
  },
  OrderCoupon: {
    post: (orderId, couponId) =>
      axios.post(getApiBaseUrl() + `/api/order/${orderId}/coupon/${couponId}/`),
    delete: (orderId, couponId) =>
      axios.delete(
        getApiBaseUrl() + `/api/order/${orderId}/coupon/${couponId}/`
      ),
  },
  Practitioner: {
    getVerificationStatus: () =>
      axios.get(getApiBaseUrl() + `/api/practitioner/verificationstatus/`),
    createInOfficeKitsRequest: (practitionerId, formData) =>
      axios.post(
        getApiBaseUrl() +
          `/api/practitioner/${practitionerId}/inofficekitsrequest/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      ),
  },
  PractitionerProfile: {
    deletePhoto: (id) =>
      axios.delete(
        getApiBaseUrl() + `/api/normalized/practitioner_profiles/${id}/photo/`
      ),
  },
  PractitionerNotifications: {
    getUnreadResultNotifications: (params) =>
      axios.get(getApiBaseUrl() + `/api/dashboard-unread-results-count/`, {
        params: params,
      }),
  },
  PractitionerLicenses: {
    post: (formData) =>
      axios.post<PractitionerLicense>(
        getApiBaseUrl() + `/api/practitionerlicenses/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      ),
  },
  PractitionerBundles: {
    get: (orderId, action, storefrontId) => {
      const queryParams: string[] = []
      if (orderId) {
        queryParams.push(`order_id=${orderId}`)
      }
      if (action) {
        queryParams.push(`action=${action}`)
      }
      if (storefrontId) {
        queryParams.push(`storefront_id=${storefrontId}`)
      }
      return axios.get(
        getApiBaseUrl() + "/api/practitionerbundles/?" + queryParams.join("&")
      )
    },
  },
  PractitionerEMRIntegrations: {
    get: () => axios.get(getApiBaseUrl() + "/api/practitionerintegrations/"),
    disconnect: (emrIntegration, disconnectionReason) =>
      axios.post(
        getApiBaseUrl() + "/api/practitionerintegrations/disconnect/",
        {
          emr_integration: emrIntegration,
          disconnection_reason: disconnectionReason,
        }
      ),
    request: (emrIntegration) =>
      axios.post(getApiBaseUrl() + "/api/practitionerintegrations/request/", {
        emr_integration: emrIntegration,
      }),
  },
  Bundle: {
    create: (data) =>
      axios.post<LabTestBundle>(getApiBaseUrl() + `/api/labtestbundles/`, data),
    delete: (bundleId) =>
      axios.delete(getApiBaseUrl() + `/api/labtestbundles/${bundleId}/`),
    update: (bundleId, data) =>
      axios.put<LabTestBundle>(
        getApiBaseUrl() + `/api/labtestbundles/${bundleId}/`,
        data
      ),
    clinicShare: (bundleId) =>
      axios.post<LabTestBundle>(
        getApiBaseUrl() + `/api/labtestbundles/${bundleId}/clinic_share/`
      ),
    clinicUnshare: (bundleId) =>
      axios.post<LabTestBundle>(
        getApiBaseUrl() + `/api/labtestbundles/${bundleId}/clinic_unshare/`
      ),
  },
  Snippet: {
    getList: (search, snippetType) =>
      axios.get(
        getApiBaseUrl() +
          `/api/snippets/?search=${search}&snippet_type=${snippetType}`
      ),
    create: (data) => axios.post(getApiBaseUrl() + `/api/snippets/`, data),
    delete: (snippetId) =>
      axios.delete(getApiBaseUrl() + `/api/snippets/${snippetId}/`),
    update: (snippetId, data) =>
      axios.put(getApiBaseUrl() + `/api/snippets/${snippetId}/`, data),
  },
  Storefront: {
    retrieveStorefronts: () =>
      axios.get(getApiBaseUrl() + `/api/normalized/storefronts/`),
    // Unused
    getStorefront: (storefrontId, include) =>
      axios.get(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/?include=${include.join(
            ","
          )}`
      ),
    updateStorefront: (storefrontId, payload, include) =>
      axios.patch(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/?include=${include.join(
            ","
          )}`,
        payload,
        { headers: { "Content-Type": "application/vnd.api+json" } }
      ),

    updateCart: (storefrontId, payload, include) =>
      axios.patch(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/cart/?include=${include.join(
            ","
          )}`,
        payload,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    createCart: (storefrontId, payload, include) =>
      axios.post(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/cart/?include=${include.join(
            ","
          )}`,
        payload,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    createStorefront: () =>
      axios.post(getApiBaseUrl() + "/api/normalized/storefronts/"),
    publish: (storefrontId, include) =>
      axios.post(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/publish/?include=${include.join(
            ","
          )}`,
        {},
        { headers: { "Content-Type": "application/vnd.api+json" } }
      ),
    createProduct: (storefrontId, payload, include) =>
      axios.post(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/product/?include=${include.join(
            ","
          )}`,
        payload,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    deleteProduct: (storefrontId, productId, include) =>
      axios.delete(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/product/${productId}/?include=${include.join(
            ","
          )}`,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    updateProduct: (storefrontId, productId, include, payload) =>
      axios.patch(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/product/${productId}/?include=${include.join(
            ","
          )}`,
        payload,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    archiveStorefront: (storefrontId) =>
      axios.post(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/archive/`,
        {},
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    createSection: (storefrontId, payload, include) =>
      axios.post(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/section/?include=${include.join(
            ","
          )}`,
        payload,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    updateSection: (storefrontId, sectionId, include, payload) =>
      axios.patch(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/section/${sectionId}/?include=${include.join(
            ","
          )}`,
        payload,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
    deleteSection: (storefrontId, sectionId, include) =>
      axios.delete(
        getApiBaseUrl() +
          `/api/normalized/storefronts/${storefrontId}/section/${sectionId}/?include=${include.join(
            ","
          )}`,
        {
          headers: { "Content-Type": "application/vnd.api+json" },
          withCredentials: true,
        }
      ),
  },
  StorefrontOrderApproval: {
    approveOrDenyStorefrontOrder: (approvalId, payload) =>
      axios.patch(
        getApiBaseUrl() + `/api/storefrontorderapproval/${approvalId}/`,
        payload
      ),
  },
  TeamMember: {
    getList: () => axios.get(getApiBaseUrl() + `/api/teammembers/`),
    create: (data) => axios.post(getApiBaseUrl() + `/api/teammembers/`, data),
    resendInvite: (data) =>
      axios.post(getApiBaseUrl() + `/api/teammembers/invite/`, data),
  },
  Phlebotomist: {
    getList: () => axios.get(getApiBaseUrl() + `/api/clinicphlebotomists/`),
    getLocations: (data) =>
      axios.get(getApiBaseUrl() + `/api/gis/phlebotomists/`, data),
    create: (data) =>
      axios.post(getApiBaseUrl() + `/api/clinicphlebotomists/`, data),
    delete: (phlebotomistId) =>
      axios.delete(
        getApiBaseUrl() + `/api/clinicphlebotomists/${phlebotomistId}/`
      ),
    update: (phlebotomistId, data) =>
      axios.put(
        getApiBaseUrl() + `/api/clinicphlebotomists/${phlebotomistId}/`,
        data
      ),
  },
  Patient: {
    create: (data) =>
      axios.post<Patient>(getApiBaseUrl() + "/api/patient/", data),
    patch: (id, patient) =>
      axios.patch(getApiBaseUrl() + `/api/patient/${id}/`, patient),
    requestPastResults: (patientId: string, practitionerId: string) =>
      axios.post(
        getApiBaseUrl() +
          `/api/patient/${patientId}/request_past_results/?prac_id=${practitionerId}`
      ),
  },
  PatientCheckout: {
    get: (token) =>
      axios.get(getApiBaseUrl() + "/api/patientcheckout?token=" + token),
    post: (token, data) =>
      axios.post(
        getApiBaseUrl() + "/api/patientcheckout/?token=" + token,
        // Credentials are included to delete a storefront session upon checkout
        data,
        { withCredentials: true }
      ),
    pause: (token, data) =>
      axios.post(
        getApiBaseUrl() + "/api/patientcheckout/pause/?token=" + token,
        data
      ),
    cancel: (token, data) =>
      axios.post(
        getApiBaseUrl() + "/api/patientcheckout/cancel/?token=" + token,
        data
      ),
    update: (token, data) =>
      axios.post(
        getApiBaseUrl() + "/api/patientcheckout/update/?token=" + token,
        data
      ),
    createIdentityVerificationSession: (token) =>
      axios.post(
        `${getApiBaseUrl()}/api/patientcheckout/identity-verification/?token=${token}`
      ),
    markIdentityVerificationProcessing: (token, verificationId, version) =>
      axios.post(
        `${getApiBaseUrl()}/api/patientcheckout/identity-verification/${verificationId}/processing/?token=${token}&version=${version}`
      ),
    toggleInsurance: (id, toggleTo, companyKey, token) =>
      axios.post(
        getApiBaseUrl() +
          `/api/patientcheckout/toggle-insurance/?token=${token}`,
        {
          toggle_to: toggleTo,
          company_key_to_toggle: companyKey,
        }
      ),
    disableLineItems: (token: string, orderedTestIds: string[]) =>
      axios.post(
        getApiBaseUrl() +
          `/api/patientcheckout/lineitems/disable/?token=${token}`,
        {
          ordered_test_ids: orderedTestIds,
        }
      ),
    enableLineItems: (token: string, orderedTestIds: string[]) =>
      axios.post(
        getApiBaseUrl() +
          `/api/patientcheckout/lineitems/enable/?token=${token}`,
        {
          ordered_test_ids: orderedTestIds,
        }
      ),
  },
  PatientPortal: {
    getOrder: (token, orderId) =>
      axios.get(
        getApiBaseUrl() + `/api/orderinstructions/${orderId}/?token=${token}`
      ),
  },
  PaymentCollection: {
    get: (token) =>
      axios.get(getApiBaseUrl() + `/api/payment-collection/?token=${token}`),
    post: (token, formData) =>
      axios.post(
        getApiBaseUrl() + `/api/payment-collection/?token=${token}`,
        formData
      ),
  },
  PaymentCard: {
    delete: (id) => axios.delete(getApiBaseUrl() + `/api/paymentcard/${id}`),
    getList: () => axios.get(getApiBaseUrl() + "/api/paymentcard/"),
    post: (paymentMethodId, isSharedWithClinic) =>
      axios.post(getApiBaseUrl() + "/api/paymentcard/", {
        payment_method_id: paymentMethodId,
        is_shared_with_clinic: isSharedWithClinic,
      }),
    patch: (id, payload) =>
      axios.patch(getApiBaseUrl() + `/api/paymentcard/${id}`, payload),
  },
  ResendRegistrationEmail: {
    post: () =>
      axios.post(getApiBaseUrl() + "/api/users/resendregistrationemail/"),
  },
  SearchSuggestions: {
    get: (search, limit) =>
      axios.get(
        getApiBaseUrl() +
          `/api/searchsuggestions/?search=${search}${
            typeof limit === "number" ? `&limit=${limit}` : ""
          }`
      ),
  },
  SplitIt: {
    initiate: (token) =>
      axios.post(getApiBaseUrl() + `/api/splitit/initiate/?token=${token}`),
  },
  PasswordResetToken: {
    validate: (token) =>
      axios.post(getApiBaseUrl() + `/api/password_reset/validate_token/`, {
        token,
      }),
  },
  Waffle: {
    getWaffleJS: () =>
      axios.get(`${getApiBaseUrl()}/api/waffle/wafflejs`, {
        withCredentials: true,
      }),
  },
  Features: {
    getClinicABStatus: () =>
      axios.get(`${getApiBaseUrl()}/api/features/clinic-ab-status/`),
  },
  SupportedCountries: {
    getList: () =>
      axios.get<SupportedCountry[]>(
        `${getApiBaseUrl()}/api/supported_countries/`
      ),
  },
  InformationRequest: {
    // Unused
    post: (data) =>
      axios.post(
        getApiBaseUrl() + `/api/normalized/information_request/`,
        data
      ),
  },
  RupaBloodDashboards: {
    removeAllClinicOptimalRanges: (key) =>
      axios.post(
        `${getApiBaseUrl()}/api/normalized/lab_company_biomarker_range/clear_clinic_ranges/`,
        {
          lab_company_key: key,
        }
      ),
  },
  User: {
    getClinicDocumentAccess: (userId, clinicId) =>
      axios.get(
        `${getApiBaseUrl()}/api/patient_portal/user/${userId}/clinic_document_access/${clinicId}/`
      ),
    patchClinicDocumentAccess: (userId, clinicId, data) =>
      axios.patch(
        `${getApiBaseUrl()}/api/patient_portal/user/${userId}/clinic_document_access/${clinicId}/`,
        data
      ),
  },
}

export function getAPIEndpoint(endpoint) {
  return axios.get(getApiBaseUrl() + endpoint)
}
