import { useEffect } from "react"

import { FormProvider, useFormContext, useFormState } from "react-hook-form"

import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormControl, FormHelperText } from "@material-ui/core"
import { Button, cn } from "@rupahealth/design"

import { ReactComponent as CircleCheckIcon } from "app/assets/icons/circular/circle-check.svg"
import ControlledDatePicker from "app/components/forms/ControlledDatePicker"
import ControlledSubmitButton from "app/components/forms/ControlledSubmitButton"
import BaseFileInput from "app/components/upload/BaseFileInput"
import Dropzone from "app/components/upload/dropzone/Dropzone"
import { PRACTITIONER_LICENSE_ALLOWED_FILE_TYPES } from "app/components/upload/helpers"
import {
  ControlledSelectField,
  ControlledTextField,
} from "app/main/patient-checkout/fields"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import { Practitioner } from "app/types"
import { capitalizeFirstLetter } from "app/utils"
import { PractitionerLicenseCreateForm } from "types/practitioner-license"

import { useCredentialModalContext } from "./CredentialModalProvider"
import { CredentialIcon } from "./components/CredentialIcon"
import { licenseTitleName, LicenseType } from "./types"
import useLicenseCreateForm from "./use-license-create-form"

interface Props {
  practitioner: Practitioner
}

const Step2LicenseUpload: React.FC<Props> = ({ practitioner }) => {
  const { goBackStep, licenseType, uploadLicense, setDirty } =
    useCredentialModalContext()

  const formMethods = useLicenseCreateForm({ licenseType, practitioner })

  const {
    formState: { isSubmitting, isDirty },
    setError,
  } = formMethods

  useEffect(() => setDirty(isDirty), [isDirty, setDirty])

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formMethods.handleSubmit((data) => uploadLicense(data, setError))()
        }}
        noValidate
      >
        <div className="bg-slate-50">
          <LicenseUploadInner />
        </div>
        <div className="flex gap-4 border-t border-slate-200 bg-white py-4 px-5 text-base15 font-semibold">
          <Button
            variant="outline"
            className="w-full"
            disabled={isSubmitting}
            onClick={goBackStep}
          >
            Go Back
          </Button>
          <ControlledSubmitButton children="Continue" />
        </div>
      </form>
    </FormProvider>
  )
}

const LicenseUploadInner: React.FC = () => {
  const { licenseType, practitionerTypeOptions } = useCredentialModalContext()
  const { setValue, watch, control } =
    useFormContext<PractitionerLicenseCreateForm>()

  const { isSubmitting, errors } = useFormState({ control })

  const file = watch("file")

  const onFilesAdded = (files: File[]) => {
    // We only care about the first file as we do not support multiple files
    const file = files[0]
    setValue("file", file, { shouldDirty: true, shouldValidate: true })
  }

  const licenseTypeName = licenseTitleName(licenseType)

  return (
    <div className="p-5">
      <div className="font-title text-xl19 font-semibold text-center mb-4">
        Upload your {licenseTypeName}.
      </div>

      <div className="bg-white p-4 rounded-xl text-sm flex flex-col md:flex-row gap-[22px]">
        <BaseFileInput
          onFilesAdded={onFilesAdded}
          disabled={!!file}
          className="flex-1"
          allowedFileTypes={PRACTITIONER_LICENSE_ALLOWED_FILE_TYPES}
        >
          {({ isDragging, handleClick }) => (
            <FormControl error={!!errors.file} className="w-full">
              <Dropzone
                isDragging={isDragging}
                className={cn("p-[22px] text-body", {
                  "bg-slate-50": !file,
                  "border-blue-300 border-solid bg-blue-50": !!file,
                  "border-red-500": !!errors.file,
                })}
                hideUploadLogo
                disabled={!!file}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClick(e)
                  }}
                  className="flex flex-col items-center gap-5 text-base15"
                >
                  <div className="font-semibold text-lg17 ">
                    {capitalizeFirstLetter(licenseTypeName)}
                  </div>
                  <CredentialIcon licenseType={licenseType} />
                  {!file ? (
                    <div className="flex flex-col items-center gap-4">
                      <span className="text-slate-500">Drag and drop.</span>
                      <Button
                        variant="outline-primary"
                        className="w-full shadow-sm text-[15px]"
                        onClick={(e) => e.preventDefault()}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="text-primary mr-2"
                        />{" "}
                        Upload {capitalizeFirstLetter(licenseTypeName)}
                      </Button>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center">
                      <div className="flex gap-2 items-center">
                        <CircleCheckIcon className="fill-emerald-500 flex-shrink-0" />
                        <span className="text-left">{file.name}</span>
                      </div>
                      <Button
                        variant="ghost"
                        className="text-primary font-semibold p-0 hover:text-primary-600"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          handleClick(e)
                        }}
                      >
                        Replace
                      </Button>
                    </div>
                  )}
                </div>
              </Dropzone>
              {errors.file && (
                <FormHelperText className="mt-2 text-red-500">
                  {errors.file.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </BaseFileInput>

        <div className="flex-1 flex flex-col gap-4 justify-center">
          <ControlledTextField
            name="license_number"
            label={
              licenseType === LicenseType.license
                ? "License Number"
                : "Certificate Number"
            }
            required
            disabled={isSubmitting}
          />
          {licenseType === LicenseType.license && (
            <ControlledSelectField
              name="state"
              label="State Licensed"
              required
              disabled={isSubmitting}
            >
              {Object.entries(US_STATES).map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </ControlledSelectField>
          )}
          <ControlledSelectField
            name="practitioner_type_id"
            label={
              licenseType === LicenseType.license
                ? "Practitioner Type"
                : "Certification Type"
            }
            required
            disabled={isSubmitting || !practitionerTypeOptions}
            hideEmptyOption
          >
            {practitionerTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </ControlledSelectField>
          <ControlledTextField
            name="npi_number"
            label={
              <span>
                NPI Number <span className="font-normal">(if applicable)</span>
              </span>
            }
            disabled={isSubmitting}
          />
          <ControlledDatePicker
            name="expiration_date"
            disabled={isSubmitting}
            modal
            calendarProps={{
              fromYear: new Date().getFullYear(),
              toYear: new Date().getFullYear() + 100,
              captionLayout: "dropdown",
            }}
            fieldLabelProps={{
              label: (
                <span>
                  Expiration Date
                  {licenseType === LicenseType.certificate && (
                    <span className="font-normal"> (if applicable)</span>
                  )}
                </span>
              ),
              required: licenseType === LicenseType.license,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Step2LicenseUpload
